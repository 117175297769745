import ImageButtonComponent from '@wix/thunderbolt-elements/src/components/ImageButton/viewer/ImageButton';
import ImageButtonController from '@wix/thunderbolt-elements/src/components/ImageButton/viewer/ImageButton.controller';


const ImageButton = {
  component: ImageButtonComponent,
  controller: ImageButtonController
};


export const components = {
  ['ImageButton']: ImageButton
};

